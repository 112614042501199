import Vue from 'vue'
import App from './App.vue'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import router from './router.js'
import 'material-icons/iconfont/material-icons.css';

import './themeConfig.js'

import i18n from './i18n/i18n'
import vuescroll from 'vuescroll';
Vue.use(vuescroll)

import BackToTop from 'vue-backtotop'
Vue.use(BackToTop)

import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
