<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  created () {
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)
  }
}
</script>


<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 75vh;

  &:not(.ps) {
    overflow-y: auto;
  }
}
.vdp-datepicker{
  input{
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
    color: gray;
    width: 98%;
  }
  .day.blank {
    background: transparent;
  }
  .cell {
    &.day {
      border-radius: 50%;
    }
    &.month,
    &.year {
      border-radius: .5rem;
    }
  }
}
.hero { 
  position: relative; 
  height: 600px;
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  background-size: cover;
  background-position: center;
  
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.50);
  }
}

.hero-text {
  position: relative;
  color: #ffffff;  
  line-height: 0.9;
}
</style>


<style>
.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  margin-left: 17px !important;
}
li .vs-breadcrumb--link{
  color: #fff !important;
}
li .vs-breadcrum--separator{
  color: #fff !important;
}
.vs-popup {
  width: 70% !important;
}
.vs-select--item-icon {
  margin-right: 10px !important;
}
.vs-popup--title {
  color: #00DCDC;
}
.search-input .input-span-placeholder, .search-input .vs-input--input {
  padding-left: 35px !important;
}
.search-input {
  padding-left: 0px !important;
}
.vs-input-number--button-plus, .vs-input-number--button-less {
  background-color: #fff !important;
  color: #00DCDC !important;
  border: 2px solid #00DCDC !important;
  border-radius: 50% !important;
}
.vs-input-number button i {
  font-weight: bolder !important;
}
.vs-input-number--input {
  border-bottom: 1px solid rgb(0, 220, 220) !important;
}
.text-blue {
  color: #00DCDC;
}
.text-orange {
  color: #F89416;
}
.top-picks .vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  align-items: baseline !important;
  color: black;
  justify-content: end !important;
  text-align: left;
}
.top-picks .vueperslide__content-wrapper .vueperslide__title {
  background: white;
  padding: 10px;
  font-size: 20px;
  /* line-height: 1.75rem; */
  /* border-top-right-radius: 2rem; */
  /* width: 60%; */
}
.top-picks .vueperslides__inner, .top-picks .vueperslides__track {
  width: 100% !important;
  height: 250px !important;
}
.top-picks .vueperslides__parallax-wrapper {
  position: static !important;
}
@media (max-width: 600px) {
  .top-picks .vueperslides__bullets {
    bottom: -70px !important;
  }
  .top-picks .vueperslides__bullet .default {
    background-color: #000 !important;
  }
}
.activity-detail .vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  align-items: baseline !important;
  color: black;
  justify-content: end !important;
  text-align: left;
}
.activity-detail .vueperslide__content-wrapper .vueperslide__title {
  background: white;
  padding: 21px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border-top-right-radius: 2rem;
  width: 60%;
}
.vueperslides__arrow svg {
  background: white;
  border-radius: 50%;
  font-size: 12px !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #F89416 !important;
}
.vueperslides__arrow--prev {
  right: auto;
  left: 0.3em !important;
}
.vueperslides__arrow--next {
  left: auto;
  right: 0.3em !important;
}
input.phoneInput {
  border: none !important;
}
.el-carousel__arrow {
  background-color: #F89416 !important;
}
.el-carousel__arrow:hover {
  background-color: rgba(31,45,61,.23) !important;
}
[dir = 'rtl'] .el-tabs__nav {
  float: right;
}
[dir = 'ltr'] .el-tabs__nav {
  float: left;
}
[dir = 'rtl'] .el-tabs--card>.el-tabs__header .el-tabs__nav {
  float: right;
}
[dir = 'ltr'] .el-tabs--card>.el-tabs__header .el-tabs__nav {
  float: left;
}
.el-tabs--card>.el-tabs__header,
.el-tabs--card>.el-tabs__header .el-tabs__nav,
.el-tabs--card>.el-tabs__header .el-tabs__item {
  border: none !important;
}
.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  background: #F89416;
  color: #fff;
  border-radius: 7px;
}
.el-tabs--card>.el-tabs__header .el-tabs__item.is-active:hover {
  color: #fff;
}
.el-tabs--card>.el-tabs__header .el-tabs__item:hover {
  color: #000;
}
.vs-input--icon {
  top: 4px !important;
}
.vs-con-input input {
  height: 10%;
}
.v-facebook-login span, .v-facebook-login svg {
  display: none !important;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  overflow-x: hidden;
} */
</style>
