
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  history: true,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/en'
    },
    {
      path: '/:lang',
      component: () => import('@/layouts/Layout.vue'),
      children: [
        {
          path: '/en',
          redirect: '/en/home'
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/LogIn.vue')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/views/signUp.vue')
        },
        {
          path: 'home',
          name: 'home',
          component: () => import('@/views/Home.vue')
        },
        {
          path: 'activity/:activityID',
          name: 'activity-details',
          component: () => import('@/views/ActivityDetails.vue')
        },
        {
          path: 'cart',
          name: 'cart',
          component: () => import('@/views/AddToCart.vue')
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/views/Profile.vue')
        },
        {
          path: 'store/:categoryId',
          name: 'store',
          component: () => import('@/views/Store.vue')
        },
        {
          path: 'store/:categoryId/products/:productId',
          name: 'product-view',
          component: () => import('@/views/ProductView.vue')
        },
        {
          path: 'clubs/:clubID',
          name: 'club-details',
          component: () => import('@/views/ClubDetails.vue')
        },
        {
          path: 'interests/:interestID',
          name: 'interest-details',
          component: () => import('@/views/InterestDetails.vue')
        },
        {
          path: 'members',
          name: 'manage-members',
          component: () => import('@/views/ManageMembers.vue')
        },
        {
          path: 'members/:memberID',
          name: 'member-details',
          component: () => import('@/views/MemberDetails.vue')
        },
        {
          path: 'members/:memberID/interests/:interestID',
          name: 'member-interests-details',
          component: () => import('@/views/MemberDetailsInterests.vue')
        },
        {
          path: 'search-results',
          name: 'search-results',
          component: () => import('@/views/SearchResults.vue')
        },
        {
          path: 'favourites',
          name: 'favourites',
          component: () => import('@/views/Favorite.vue')
        },
        {
          path: 'history',
          name: 'history',
          component: () => import('@/views/History.vue')
        },
        {
          path: 'notifications',
          name: 'notifications',
          component: () => import('@/views/Notifications.vue')
        }
      ]
    }
  ]
})

// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

router.beforeEach((to, from, next) => {
//   if (to.meta.authRequired) {
//     if (!(auth.isAuthenticated())) {
//       router.push({ path: 'pages/login', query: { to: to.path } }).catch(() => {})
//     }
//   }

  return next()
})

export default router