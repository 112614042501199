export const colors = {
  primary : '#00DCDC',
  success : '#28C76F',
  danger  : '#EA5455',
  warning : '#F89416',
  dark    : '#1E1E1E',
  secondary: '#6c757d'
}
const themeConfig = {
  rtl               : false,
  userInfoLocalStorageKey: 'userInfo'
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, { theme:{ colors }, rtl: themeConfig.rtl })

export default themeConfig
