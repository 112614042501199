// en, ar
export default {
  en: {
    'Stay home & Join Online Courses': 'Stay home & Join Online Courses',
    'Discover': 'Discover',
    'Top Picks': 'Top Picks',
    'Our Clients': 'Our Clients',
    'No Data Available': 'No Data Available',
    'Price: ': 'Price: ',
    'Save': 'Save',
    'Share': 'Share',
    'Schedule': 'Schedule',
    'Gallery': 'Gallery',
    'Special Order': 'Special Order',
    'At': 'At',
    'Years': 'Years',
    'KWD': 'KWD',
    'Book Now': 'Book Now',
    'View Details': 'View Details',
    'Show More': 'Show More',
    'My Cart': 'My Cart',
    'Add All To Wish List': 'Add All To Wish List',
    'Remove All': 'Remove All',
    'Promocode': 'Promocode',
    'Enter your promocode if you have it': 'Enter your promocode if you have it',
    'Shipping Address': 'Shipping Address',
    'Enter Code': 'Enter Code',
    'Apply': 'Apply',
    'Shipping': 'Shipping',
    'Total Price: ': 'Total Price: ',
    'Confirm & pay': 'Confirm & pay',
    'Remove': 'Remove',
    '1 Activity For': '1 Activity For',
    'Children': 'Children'
  },
  ar: {
    'Stay home & Join Online Courses': 'ابق في المنزل وانضم إلى الدورات التدريبية عبر الإنترنت',
    'Discover': 'اكتشف',
    'Top Picks': 'افضل الاختيارات',
    'Our Clients': 'عملائنا',
    'No Data Available': 'لا توجد بيانات متوفرة',
    'Price: ': 'المبلغ: ',
    'Save': 'حفظ',
    'Share': 'مشاركة',
    'Schedule': 'البرنامج',
    'Gallery': 'معرض الصور',
    'Special Order': 'طلب خاص',
    'At': 'في',
    'Years': 'سنين',
    'KWD': 'درهم كويتي',
    'Book Now': 'احجز الآن',
    'View Details': 'عرض التفاصيل',
    'Show More': 'عرض المزيد',
    'My Cart': 'سلة المشتريات',
    'Add All To Wish List': 'إضافة الكل إلى قائمة الرغبات',
    'Remove All': 'مسح الكل',
    'Promocode': 'رمز ترويجي',
    'Enter your promocode if you have it.': 'أدخل الرمز الترويجي الخاص بك إذا كان لديك.',
    'Shipping Address': 'عنوان الشحن',
    'Enter Code': 'ادخل الرمز',
    'Apply': 'ارسل',
    'Shipping: ': 'شحن: ',
    'Total Price: ': 'المبلغ الكلى: ',
    'Confirm & pay': 'اكد و ادفع',
    'Remove': 'مسح',
    '1 Activity For': 'نشاط واحد لـ',
    'Children': 'اطفال'
  }
}
